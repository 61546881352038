const financialAid = 'dae/financial-aid/demand-collection/'

// Demand Collection Common Api Routes...
export const farmerDataApi = financialAid + 'farmer-data'
export const circularDataApi = financialAid + 'circular-data'
export const designationWiseUser = '/dae/subsidy/bill-prepare/get-user'
export const demandApproveForward = financialAid + 'all-approve-and-forward'

// Demand Collection Api Routes...
export const demandCollectionList = financialAid + 'list'
export const demandCollectionStore = financialAid + 'store'
export const demandCollectionUpdate = financialAid + 'update'
export const demandCollectionReceive = financialAid + 'receive'

// Demand Collection Request Api Routes...
export const subsidyDemandCollectionRequestList = financialAid + 'request-list'
export const upazillaDemandReqList = financialAid + 'upazilla-wise-request-list-details'

// Demand Collection Received Api Routes...
export const demandCollectionReceivedList = financialAid + 'received-list'
export const upazillaDemandRcvList = financialAid + 'upazilla-wise-receive-list-details'

// Demand Collection Approve Api Routes...
export const demandCollectionApprovedList = financialAid + 'approved-list'
export const upazillaDemandApproveDetails = financialAid + 'upazilla-wise-approve-details'

// Distribution Routes...
export const distributionPendingList = financialAid + 'distribution-pending-list'
export const singleDistirbutionPendingDetails = financialAid + 'single-distribution-pending-details'
export const singleDistirbutionPendingList = financialAid + 'single-distribution-pending'
export const singleDistirbuteGrantAlloDistr = financialAid + 'single-distribute'

const distribution = 'dae/financial-aid/distribution/'
export const getApplicationDataApi = distribution + 'application'
export const getDistApplicantApi = distribution + 'get-dist-applicant-list'
export const distributionList = distribution + 'list'
export const distributionStore = distribution + 'store'

const payroll = 'dae/financial-aid/payroll/'
export const getUpazillaPayrollFarmer = payroll + 'upazilla-distribution'
export const upazillaPrepare = payroll + 'upazilla-prepare'

// Farmer Selection routes
const farmerSelect = 'dae/financial-aid/farmer-selection/'
export const getFarmer = farmerSelect + 'get-farmer'
export const farmerSelectList = farmerSelect + 'list'
export const farmerSelectStore = farmerSelect + 'store'
export const farmerSelectShow = farmerSelect + 'show'
export const getFinancialAidFarmer = 'dae/subsidy/payroll/financial-aid-farmer'
export const financialAidPrepare = 'dae/subsidy/payroll/financial-aid-prepare'
